<template>
  <div class="cus-container">
    <div>
      <img
        class="head-m"
        v-lazy="require('../../../assets/images/bg_1.png')"
      />
    </div>
    <div class="cus-head">
      <div
        class="page-outter"
        style="width: 70%;"
      >
        <div align="center">
          <img
            class="cus-title"
            v-lazy="require('../../../assets/images/title19.png')"
          />
        </div>
        <div class="cus-d1 swiper-container">
          <div class="swiper-wrapper">
            <div
              class="cus-d1-1 swiper-slide"
              v-for="(item,index) in tds"
              :key="index"
            >
              <img
                class="m1"
                :src="item.img"
              />
              <div>
                <span class="s1"></span>
                <span class="s2">{{item.label}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);
export default {
  name: "honor",
  data() {
    return {
      mySwiper: null,
      tds: [
        {
          img: require("./images/hmydrz.png"),
          label: "华民药店端软著",
        },
        {
          img: require("./images/jwyyglptrz.png"),
          label: "九为医药管理平台软著",
        },
        {
          img: require("./images/jyyhzdrz.png"),
          label: "九医云患者端软著",
        },
        {
          img: require("./images/syzl.png"),
          label: "首营资料系统软件软著",
        },
        {
          img: require("./images/ypsyrz.png"),
          label: "药品溯源软著",
        },
        {
          img: require("./images/zzdxywjy.jpg"),
          label: "增值电信业务经营许可证",
        },
        {
          img: require("./images/lishidanw.png"),
          label: "理事单位",
        },
        {
          img: require("./images/hlwzgzs.jpg"),
          label: "互联网药品信息服务资格证书",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.createSwiper();
    });
  },
  methods: {
    createSwiper() {
      this.mySwiper = new Swiper(".swiper-container", {
        direction: "horizontal" /*横向滑动*/,
        slidesPerView: 4,
        loop: true,
        observeParents: false, //必须加入
        observer: true, //必须加入
        autoplay: {
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay （没有加入之前轮播图设置 autoplay：true无效）
          delay: 2000, // 自动切换的时间间隔（单位ms）
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.cus-container {
  position: relative;
  width: 100%;
}
.cus-head {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid #fff;
  padding-bottom: 2.4rem;
}
.head-m {
  width: 100%;
  height: 60px;
  display: block;
}
.cus-title {
  width: 15rem;
  margin-top: 2.4rem;
  display: block;
}
.cus-d1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 60px;
  .cus-d1-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .m1 {
      display: block;
      width: 6.4rem;
      border: 6px solid #e5e6e5;
    }
    span {
      display: inline-block;
    }
    .s1 {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #6aa5ff;
    }
    .s2 {
      font-size: 14px;
      color: #4a4a4a;
      margin-left: 10px;
    }
  }
}
.cus-head {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid #fff;
  padding-bottom: 2.4rem;
}
</style>